let icon = document.getElementById('hamburger-icon')
let closeIcon = document.getElementById('hamburger-close-icon')

// Hamburger navigation
let drawer = document.getElementsByClassName('links')[0]
icon.onclick = () => {
  drawer.classList.add('links-visible')
}
closeIcon.onclick = () => {
  drawer.classList.remove('links-visible')
}

// Active navigation links

let links = document.getElementsByClassName('links')[0]
let linksChildren = links.children

// Remove current active class
document.getElementsByClassName('active')[0].classList.remove('active')

for (let i = 0; i < linksChildren.length; i++) {
  let link = linksChildren[i]

  if (link.href && link.href.indexOf(window.location.pathname) !== -1) {
    link.classList.add('active')
    break
  }
  if (window.location.pathname === '/') {
    linksChildren[1].classList.add('active')
    break
  }
}

// Help us dropdown
const dropdown = document.getElementById('help-us-dropdown')
const helpUs = document.getElementById('help-us')
helpUs.onclick = () => {
  if (dropdown.classList.contains('d-none')) {
    dropdown.classList.remove('d-none')
    dropdown.classList.add('d-block')
  } else {
    dropdown.classList.remove('d-block')
    dropdown.classList.add('d-none')
  }
}
document.addEventListener('click', (e) => {
  if (e.target.id !== 'help-us') {
    dropdown.classList.remove('d-block')
    dropdown.classList.add('d-none')
  }
})
